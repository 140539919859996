<template>
    <div>
<!--        <div class="main-content-header">-->
<!--            <h4><strong>Settings</strong></h4>-->
<!--        </div>-->
        <div class="dashboard-main-content-wrapperxx">
            <!--        <div class="row m-3">-->
            <!--            <h4><strong>Email</strong></h4>-->
            <!--            <div class="row setting-detail-container">-->
            <!--                <h4 class="m-1">Email : {{userEmail}} (not verified)</h4>-->
            <!--                <div class="row mt-2 ml-1">-->
            <!--                    <button class="btn btn-primary">Verify email</button>-->
            <!--                </div>-->
            <!--            </div>-->
            <!--        </div>-->

            <div class="row m-3">
                <div class="row">
                    <h4 class="mb-2"><strong>Change password</strong></h4>
                </div>
                <div class="row">
                    <div class="row">
                        <div class="col-md-6 col-xs-12 col-sm-12 col-lg-6 mb-3">
                            <label for="current-password" class="control-label"> Current Password</label>
                            <div class="input-group">
                                <input type="password" id="current-password"
                                       name="current-password"
                                       class="form-control" v-model="currentPassword">
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6 col-xs-12 col-sm-12 col-lg-6 mb-3">
                            <label for="new-password" class="control-label"> New Password</label>
                            <div class="input-group">
                                <input type="password" id="new-password"
                                       name="new-password"
                                       class="form-control" v-model="newPassword">
                            </div>
                            <small><span style="font-size: 0.750rem !important;"
                                         :class="{'text-error' : validationNewPasswordError}">Password should be at least 6 characters,
                                contain at least one numeric digit, one uppercase and one lowercase letter.</span></small>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6 col-xs-12 col-sm-12 col-lg-6 mb-3">
                            <label for="retype-new-password" class="control-label"> Confirm New Password</label>
                            <div class="input-group">
                                <input type="password" id="retype-new-password"
                                       name="retype-new-password"
                                       class="form-control"
                                       v-model="confirmNewPassword"
                                       :disabled="!(newPassword.length > 0)"
                                >
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6 col-xs-12 col-sm-12 col-lg-6 mb-3">
                            <p v-if="changePasswordError" class="text-error">Unable to change password. Please try
                                again.</p>
                            <p v-else-if="validationPasswordSimilarError" class="text-error">Your new password must not be the same as your old password.</p>
                            <p v-else-if="changePasswordSuccessful" class="text-success">Password has been changed
                                successfully !</p>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6 col-xs-12 col-sm-12 col-lg-6 mb-3">
                            <div v-if="showChangeButton">
                                <button class="btn btn-primary mr-2" @click="saveNewPassword()"
                                        :disabled="isConfirmPasswordSame">
                                    Change
                                </button>
                            </div>
                            <div v-else>
                                <button class="btn btn-primary" v-if="changePasswordSuccessful" @click="goToHomepage()">
                                    Go to homepage
                                </button>
                                <button class="btn btn-warning" v-if="changePasswordError" @click="tryAgain()">
                                    Try again
                                </button>

                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>


    </div>
</template>
<script>
    import {mapGetters, mapActions} from 'vuex';

    export default {
        data() {
            return {
                userEmail: "",

                currentPassword: "",
                newPassword: "",
                confirmNewPassword: "",

                changePasswordSuccessful: false,
                changePasswordError: false,

                currentPasswordError: false,

                showChangeButton: true,

                validationNewPasswordError: false,
                validationPasswordSimilarError : false,

                debugConsole : false,

            }
        },
        computed: {
            ...mapGetters(["userDetailsGetter"]),


            isConfirmPasswordSame() {
                if (!this.newPassword || this.newPassword !== this.confirmNewPassword) {
                    return true;
                } else {
                    return false;
                }
            }


        },
        mounted() {
            //check if user email has verified from API
            this.userEmail = this.userDetailsGetter && this.userDetailsGetter.email;
        },
        metaInfo: {
            title: 'TransCrypt - Dashboard Settings | Send Money Faster with Cryptocurrency',
        },
        methods: {
            ...mapActions(["changePassword"]),
            saveNewPassword() {

                if (!(this.checkPassword(this.newPassword))) {
                    this.validationNewPasswordError = true;
                } else {
                    this.validationNewPasswordError = false;
                }

                if (this.currentPassword === this.newPassword) {
                    this.validationPasswordSimilarError = true;
                } else {
                    this.validationPasswordSimilarError = false;
                }


                if (this.validationNewPasswordError || this.validationPasswordSimilarError) {
                    return;
                }

                let data = {};
                data.old_password = this.currentPassword;
                data.new_password = this.newPassword;

                this.changePassword(data).then(res => {
                   if(this.debugConsole) console.log("change password result in settings", res);

                    if (res.data && res.data.status === "OK") {
                        this.showChangeButton = false;
                        this.changePasswordSuccessful = true;
                        this.changePasswordError = false;
                        this.validationNewPasswordError = false;


                        this.currentPassword = "";
                        this.newPassword = "";
                        this.confirmNewPassword = "";
                    } else {
                        this.changePasswordError = true;
                    }
                }).catch(err => {
                    if(this.debugConsole) console.log("Change password error in settings", err.response);

                    if (err.response && err.response.data && err.response.data.errors && err.response.data.errors.find(item => item.message === "invalid" && item.path === "password")) {
                        this.validationNewPasswordError = true;
                        this.changePasswordError = true;
                        this.showChangeButton = false;
                    } else if (err.response.data.message === 'validation_error') {
                        this.showChangeButton = false;
                        this.changePasswordError = true;
                    }
                })
            },
            tryAgain() {
                this.currentPassword = "";
                this.newPassword = "";
                this.confirmNewPassword = "";

                this.showChangeButton = true;
                this.changePasswordSuccessful = false;
                this.changePasswordError = false;

                this.currentPasswordError = false;
                this.validationNewPasswordError = false;
            },
            goToHomepage() {
                this.$router.push('/dashboard');
                this.currentPassword = "";
                this.newPassword = "";
                this.confirmNewPassword = "";

                this.showChangeButton = true;

                this.changePasswordSuccessful = false;
                this.changePasswordError = false;

                this.currentPasswordError = false;
                this.validationNewPasswordError = false;
            }
        },

    }

</script>